@font-face {
  font-family: 'nal_v4';
  src:  url('../IconFont/nal_v4.eot?1qoa1b');
  src:  url('../IconFont/nal_v4.eot?1qoa1b#iefix') format('embedded-opentype'),
    url('../IconFont/nal_v4.ttf?1qoa1b') format('truetype'),
    url('../IconFont/nal_v4.woff?1qoa1b') format('woff'),
    url('../IconFont/nal_v4.svg?1qoa1b#nal_v4') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nal_v4' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 25px;
  color: #424242;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-trak_outlined:before {
  content: "\e953";
}
.icon-container:before {
  content: "\e947";
}
.icon-container_drayage:before {
  content: "\e948";
}
.icon-container_half:before {
  content: "\e949";
}
.icon-expedite:before {
  content: "\e94a";
}
.icon-half_trak:before {
  content: "\e94b";
}
.icon-money_outlined:before {
  content: "\e94c";
}
.icon-pallet:before {
  content: "\e94d";
}
.icon-parcel:before {
  content: "\e94e";
}
.icon-plane_outlined:before {
  content: "\e94f";
}
.icon-train:before {
  content: "\e950";
}
.icon-volume_outlined:before {
  content: "\e951";
}
.icon-warehouse_outlined:before {
  content: "\e952";
}
.icon-pointer_outlined:before {
  content: "\e93d";
}
.icon-info_outlined:before {
  content: "\e93e";
}
.icon-date_outlined:before {
  content: "\e93f";
}
.icon-documents_outlined:before {
  content: "\e940";
}
.icon-download_outlined:before {
  content: "\e941";
}
.icon-folder_outlined:before {
  content: "\e942";
}
.icon-get_outlined:before {
  content: "\e943";
}
.icon-money_outlined1:before {
  content: "\e944";
}
.icon-print_outlined:before {
  content: "\e945";
}
.icon-way:before {
  content: "\e946";
}
.icon-claim:before {
  content: "\e936";
}
.icon-close:before {
  content: "\e937";
}
.icon-copy:before {
  content: "\e938";
}
.icon-delete:before {
  content: "\e939";
}
.icon-edit:before {
  content: "\e93a";
}
.icon-open_in_new:before {
  content: "\e93b";
}
.icon-Progress:before {
  content: "\e93c";
}
.icon-person:before {
  content: "\e900";
}
.icon-visibility:before {
  content: "\e901";
}
.icon-visibility_off:before {
  content: "\e902";
}
.icon-report:before {
  content: "\e903";
}
.icon-1:before {
  content: "\e904";
}
.icon-add:before {
  content: "\e905";
}
.icon-arrow-up:before {
  content: "\e906";
}
.icon-arrow-down:before {
  content: "\e907";
}
.icon-arrow-drop_down:before {
  content: "\e908";
}
.icon-arrow-drop_up:before {
  content: "\e909";
}
.icon-arrow-left:before {
  content: "\e90a";
}
.icon-arrow-right:before {
  content: "\e90b";
}
.icon-arrow-up1:before {
  content: "\e90c";
}
.icon-box:before {
  content: "\e90d";
}
.icon-CancelFilled:before {
  content: "\e90e";
}
.icon-chat:before {
  content: "\e90f";
}
.icon-check:before {
  content: "\e910";
}
.icon-check_box:before {
  content: "\e911";
}
.icon-chevron_left:before {
  content: "\e912";
}
.icon-chevron_right:before {
  content: "\e913";
}
.icon-dashboard:before {
  content: "\e914";
}
.icon-dashboard-1:before {
  content: "\e915";
}
.icon-destination:before {
  content: "\e916";
}
.icon-expand_more:before {
  content: "\e917";
}
.icon-filter_list:before {
  content: "\e918";
}
.icon-first_page:before {
  content: "\e919";
}
.icon-format_line:before {
  content: "\e91a";
}
.icon-full-screen:before {
  content: "\e91b";
}
.icon-fullscreen_exit:before {
  content: "\e91c";
}
.icon-half-screen:before {
  content: "\e91d";
}
.icon-indent_decrease:before {
  content: "\e91e";
}
.icon-indent_increase:before {
  content: "\e91f";
}
.icon-indeterminate_check:before {
  content: "\e920";
}
.icon-last_page:before {
  content: "\e921";
}
.icon-log-in:before {
  content: "\e922";
}
.icon-log-out:before {
  content: "\e923";
}
.icon-mail:before {
  content: "\e924";
}
.icon-more_vert:before {
  content: "\e925";
}
.icon-new-order:before {
  content: "\e926";
}
.icon-night:before {
  content: "\e927";
}
.icon-notification:before {
  content: "\e928";
}
.icon-open:before {
  content: "\e929";
}
.icon-orders:before {
  content: "\e92a";
}
.icon-origin:before {
  content: "\e92b";
}
.icon-parcels:before {
  content: "\e92c";
}
.icon-pin:before {
  content: "\e92d";
}
.icon-remove:before {
  content: "\e92e";
}
.icon-roles:before {
  content: "\e92f";
}
.icon-sales:before {
  content: "\e930";
}
.icon-search:before {
  content: "\e931";
}
.icon-toggle_off:before {
  content: "\e932";
}
.icon-uncheck_box:before {
  content: "\e933";
}
.icon-zoom_out_map:before {
  content: "\e934";
}
.icon-zoom_in_map:before {
  content: "\e935";
}
